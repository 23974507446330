// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-dog-template-js": () => import("../src/templates/dog-template.js" /* webpackChunkName: "component---src-templates-dog-template-js" */),
  "component---src-templates-blog-post-js": () => import("../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-content-page-js": () => import("../src/templates/content-page.js" /* webpackChunkName: "component---src-templates-content-page-js" */),
  "component---src-templates-gallery-page-js": () => import("../src/templates/gallery-page.js" /* webpackChunkName: "component---src-templates-gallery-page-js" */),
  "component---src-pages-blog-js": () => import("../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-questions-about-owning-a-labradoodle-js": () => import("../src/pages/questions-about-owning-a-labradoodle.js" /* webpackChunkName: "component---src-pages-questions-about-owning-a-labradoodle-js" */),
  "component---src-pages-success-js": () => import("../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

